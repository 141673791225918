<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" justify="center">
      <el-col :lg="18" :md="16" :sm="16" :xs="12">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID orden conekta, ID Conekta, ID familia, ID familia JR, Nombre Familia" v-model="searchForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          size="mini"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleExport"
          size="mini"
          type="success"
          icon="el-icon-bottom"
        >Exportar</el-button>
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="12">
        <el-select v-model="searchForm.location" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Sucursal">
          <el-option
            v-for="item in biolocationList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="12">
          <el-select v-model="searchForm.type" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Tipo de pago">
            <el-option
              v-for="tipo in types"
              :key="tipo.paymentType"
              :label="tipo.paymentType"
              :value="tipo.paymentType">
            </el-option>
          </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="16" :md="16" :sm="10" :xs="24">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xs="24" style="display: flex; justify-content: flex-end">
        <el-row type="flex" justify="end">
          <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
            :total="parseInt(totalPages)">
          </el-pagination>
        </el-row>
      </el-col>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      height="600"
      fit
      border
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column label="ID Pago" fixed width="120" align="center">
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{scope.row._id}}</div>
                <span>{{ scope.row._id.toString().substr(-4) }}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ID Orden Conekta" width="130" align="center">
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{scope.row.order_id}}</div>
                <span>{{ scope.row.order_id.toString().substr(-4) }}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ID Conekta" width="120" align="center">
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{scope.row.conekta_id}}</div>
                <span>{{ scope.row.conekta_id.toString().substr(-4) }}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ID Familia" width="120" align="center">
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{scope.row.idFamily}}</div>
                <span>{{ scope.row.idFamily.toString().substr(-4) }}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ID Familia JR" width="130" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.idFamilyJR }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre Familia" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.familyName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sucursal" width="120" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.location }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Motivo de pago" width="150" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.paymentType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Conceptos" type="expand" width="110">
        <template slot-scope="scope">
          <!-- Tabla conceptos -->
          <el-table
            :data="scope.row.concepts"
            size="mini"
            border
            highlight-current-row>
            <el-table-column label="ID Transaccion JR" width="130">
              <template slot-scope="data">
                <span>{{ data.row.idTransactionJR }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Tipo" width="150">
              <template slot-scope="data">
                <span>{{ data.row.type }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Subtipo" width="150">
              <template slot-scope="data">
                <span>{{ data.row.subType }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Monto original" width="120">
              <template slot-scope="data">
                <span>{{ data.row.originAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Descuento" width="100">
              <template slot-scope="data">
                <span>{{ data.row.discountAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Monto final" width="100">
              <template slot-scope="data">
                <span>{{ data.row.amount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Monto a pagar" width="120">
              <template slot-scope="data">
                <span>{{ data.row.unpaidAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Balance" width="100">
              <template slot-scope="data">
                <span>{{ data.row.balance }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Estudiante" width="300">
              <template slot-scope="data">
                <span>{{ data.row.studentName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Nota" width="300">
              <template slot-scope="data">
                <span>{{ data.row.note }}</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- Fin tabla conceptos -->
        </template>
      </el-table-column>
      <el-table-column label="Monto" width="130" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.total }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Estado del pago" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Monto pagado" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha registro" width="150" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.created_at | formatDateTime }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
    <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
      :total="parseInt(totalPages)">
    </el-pagination>
  </el-row>
  </div>
</template>

<script>
// import _ from 'lodash'
import { search as locationSearch } from '@/api/bioLocations.js'
import { search, getPage, getTypes, exportExcel } from '@/api/paymentsJR'

export default {
  data () {
    return {
      searchForm: {
        query: '',
        location: '',
        type: '',
        initDate: '',
        endDate: ''
      },
      filterDateRegisterRange: '',
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      totalPages: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25,
      locations: null,
      biolocationList: [
        {
          value: null,
          label: 'Todas las Sucursales'
        },
        {
          value: 'GRUTA',
          label: 'Gruta'
        }
      ],
      types: null
    }
  },
  mounted () {
    this.getLocationSelectOptions()
    this.handleSearchFilter()
    this.handleGetTypes()
  },
  methods: {
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        location: '',
        type: '',
        initDate: '',
        endDate: ''
      }
      this.handleSearchFilter()
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    async getLocationSelectOptions () {
      try {
        const response = await locationSearch({ query: '' })
        if (response.success) {
          const locationData = await response.data.data
          locationData.forEach(location => {
            this.biolocationList.push({
              label: location.name,
              value: location.key
            })
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async handleGetTypes () {
      this.loading = true
      await getTypes()
        .then((response) => {
          this.types = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleExport () {
      this.loading = true
      await exportExcel(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'Reporte-de-pagos-Conekta-' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>
